import ParticlesBackground from "../../components/ParticlesBackground/ParticlesBackground";
import SentenceToggle from "./SentenceToggle";

const Intro = () => {
  return (
    <>
      <ParticlesBackground />
      <SentenceToggle />
    </>
  );
};

export default Intro;
