import React from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { useCallback } from "react";
import './ParticlesBackground.css';
// Import your local images
import happyIcon from './dog (1).png';  // Adjust the path based on your folder structure
import sadIcon from './smile.png';      // Add other images
import neutralIcon from './paws.png';

const ParticlesBackground = () => {
  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  return (
    <div className="particles">
      <Particles
        height="100vh"
        style={{ zIndex: 1 }}
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          fullScreen: false,
          fpsLimit: 120,  // Reduce FPS limit for better control
          particles: {
            color: {
              value: ["#FF0000", "#D8BFD8"], // Red and lilac colors
            },
            collisions: {
              enable: false,  // Disable collisions
            },
            move: {
              directions: "none",
              enable: true,
              speed: 1.5,  // Ensure speed remains fixed
              random: false,  // Disable random speed variation
              outModes: {
                default: "out",
              },
              bounce: false,
              straight: false,
              decay: 0,  // Prevent decay of movement, ensuring stable speed
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200,
              },
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 120,
            },
            opacity: {
              value: 0.5,
              random: false,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false,
              },
            },
            shape: {
              type: "image",
              image: [
                { src: happyIcon, width: 100, height: 100 },   // First image
                { src: sadIcon, width: 100, height: 100 },     // Second image
                { src: neutralIcon, width: 100, height: 100 },
              ],
            },
            size: {
              value: 16, // Adjust the size as needed
              random: true,
              anim: {
                enable: false,
                speed: 20,
                size_min: 0.1,
                sync: false,
              },
            },
          },
          detectRetina: true,
        }}
      />
    </div>
  );
};

export default ParticlesBackground;
