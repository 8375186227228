import "./App.css";
import Intro from './views/Intro/Intro';

function App() {
  return (
    <>
    <Intro />
    </>
  );
}

export default App;
