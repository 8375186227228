import canela from './imgs/canela.jpg';
import canela2 from './imgs/canela2.jpeg';
import sideeye from './imgs/sideeye.jpg';
import acucar from './imgs/acucar.jpg';
import piscina from './imgs/piscina.jpg';
import proud from './imgs/proid.jpg';
import pc from './imgs/pc.jpg';

export const sentences = [
  {
    text: "Obrigado por seres tu, Leonor! ",
  },
  {
    text: "És incrível, nunca te esqueças disso! ",
  },
  {
    text: "Tens uma força que inspira toda a gente à tua volta! ",
  },
  {
    text: "Conta comigo para tudo, sempre! ",
  },
  {
    text: "Não duvides do teu valor, és incrível! ",
  },
  {
    text: "Estou sempre aqui para te apoiar! ",
  },
  {
    text: "Tenho muita sorte em te ter conhecido! ",
  },
  {
    text: "És mais forte do que imaginas! ",
  },
  {
    text: "Nunca te esqueças de que tens muitas pessoas que gostam de ti! ",
  },
  {
    text: "A tua felicidade é muito importante para mim! ",
  },
  {
    text: "Não importa o que aconteça, eu estou sempre do teu lado! ",
  },
  {
    text: "Conta comigo para tudo! ",
  },
  {
    text: "És super inteligente! ",
  },
  {
    text: "És muito importante! ",
  },
  {
    text: "Confio muito em ti! ",
  },
  {
    text: "Gosto muito de te ver feliz! ",
  },
  {
    text: "Tens um sorriso muito giro! ",
  },
  {
    text: "Adoro todos os teus outfits! ",
  },
  {
    text: "Podes explicar-me como ficas tão bem em todas as fotos? ",
  },
  {
    text: "És muito querida! ",
  },
  {
    text: "Vou-te proteger sempre! ",
  },
  {
    text: "Nunca te esqueças da Mulher que és! ",
  },
  {
    text: "Vais sempre ser des-pis-ta-da! ",
  },
  {
    text: "Podes falar sempre comigo, a qualquer hora, em qualquer lugar! ",
  },
  {
    text: "Estou sempre disponível para te ouvir e falar contigo! ",
  },
  {
    text: "Preocupo-me muito contigo! ",
  },
  {
    text: "Se estiveres bem, eu estou bem! ",
  },
  {
    text: "Obrigado por tornares o Verão passado no melhor! ",
  },
  {
    text: "Obrigado por fazeres parte da minha vida! ",
  },
  {
    text: "Tenho muito orgulho em ti! ",
    image: proud
  },
  {
    text: "Vamos ser masters em Inglês! ",
  },
  {
    text: "Estou sempre aqui para te acalmar e fazer sentir bem! ",
  },
  {
    text: "O teu sorriso e a tua felicidade são muito importantes para mim! ",
  },
  {
    text: "Nunca vou deixar de acreditar em ti! ",
  },
  {
    text: "✨ I'm just a girl ✨ ",
  },
  {
    text: "A Canela e a Çu também gostam muito de ti! Tal como eu! ",
    image: canela
  },
  {
    text: "Olá, sou a Canela, gosto muito de ti! ",
    image: canela2
  },
  {
    text: "BOMBASTIC SIDE EYEEE ",
    image: sideeye
  },
  {
    text: "Olá Nono, só para te dizer que és muito gira, sim? ",
    image: acucar
  },
  {
    text: "Estou sempre aqui para ti, tal como as minhas t-shirts :) ",
    image: piscina
  },
  {
    text: "Sempre pronto para te apoiar, e estar ao teu lado! Sempre, significa sempre! ",
    image: pc
  }
];
