import React, { useState, useEffect } from 'react';
import { TypeAnimation } from 'react-type-animation';
import { sentences } from './data';
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css'; // Import the default styles
import '../../App.css';

const SentenceToggle = () => {
  const [sentenceIndex, setSentenceIndex] = useState(0); // Track the current sentence index
  const [currentSentence, setCurrentSentence] = useState({ text: '' }); // Track the current sentence with a default empty text object
  const [clicked, setClicked] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(0); // Timer initially at 0
  const [showButton, setShowButton] = useState(true); // Show button initially
  const [showSentence, setShowSentence] = useState(false); // Control the sentence display

  const SECONDS_COUNT = 30; // Timer countdown duration in seconds

  // Countdown timer target date (e.g., New Year's Day)
  const targetDate = new Date('November 1, 2024 00:00:00').getTime();

  // Function to move to the next sentence
  const getNextSentence = () => {
    const randomIndex = Math.floor(Math.random() * sentences.length);
    const sentence = sentences[randomIndex];
    setCurrentSentence(sentence);
    return sentence;
  };

  // Save the timer and sentence state to localStorage
  const saveState = (remainingTime, sentence) => {
    const currentTime = Date.now();
    localStorage.setItem('timerStart', currentTime);
    localStorage.setItem('timeRemaining', remainingTime);
    localStorage.setItem('currentSentence', JSON.stringify(sentence)); // Save the current sentence as a stringified object
  };

  // Retrieve the timer and sentence state from localStorage
  const restoreState = () => {
    const startTime = localStorage.getItem('timerStart');
    const storedTimeRemaining = localStorage.getItem('timeRemaining');
    const storedSentence = localStorage.getItem('currentSentence'); // Retrieve the stored sentence

    if (startTime && storedTimeRemaining && storedSentence) {
      const elapsedTime = Math.floor((Date.now() - startTime) / 1000);
      const restoredTime = Math.max(0, storedTimeRemaining - elapsedTime);

      if (restoredTime > 0) {
        setTimeRemaining(restoredTime);
        setClicked(true);
        setShowSentence(true);
        setShowButton(false);
        setCurrentSentence(JSON.parse(storedSentence)); // Set the stored sentence as an object
      } else {
        localStorage.removeItem('timerStart');
        localStorage.removeItem('timeRemaining');
        localStorage.removeItem('currentSentence');
      }
    }
  };

  useEffect(() => {
    restoreState(); // Restore the timer and sentence when the component mounts

    let timer;
    if (clicked && timeRemaining > 0) {
      // Start the timer countdown when clicked
      timer = setInterval(() => {
        setTimeRemaining((prev) => {
          const newTime = prev > 1 ? prev - 1 : 0;
          saveState(newTime, currentSentence); // Save updated time remaining and sentence
          if (newTime === 0) {
            clearInterval(timer);
            setShowButton(true); // Show the button when the timer ends
            localStorage.removeItem('timerStart');
            localStorage.removeItem('timeRemaining'); // Clear the timer state when finished
            localStorage.removeItem('currentSentence'); // Clear the sentence when timer ends
          }
          return newTime;
        });
      }, 1000); // Update every 1 second
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [clicked, timeRemaining, currentSentence]);

  const handleClick = () => {
    if (!clicked || timeRemaining === 0) {
      const nextSentence = getNextSentence(); // Get and set the next sentence
      setTimeRemaining(SECONDS_COUNT); // Reset the timer to 30 seconds
      setClicked(true);
      setShowSentence(true); // Trigger the sentence to be displayed
      setShowButton(false); // Hide the button once clicked

      // Increment sentenceIndex to force re-render of TypeAnimation
      setSentenceIndex((prevIndex) => prevIndex + 1);

      saveState(SECONDS_COUNT, nextSentence); // Save initial timer state and sentence
    }
  };

  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600); // Get the number of hours
    const m = Math.floor((seconds % 3600) / 60); // Get the number of minutes
    const s = seconds % 60; // Get the remaining seconds
    return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
  };

  return (
    <div className="container">
        <div className='countdown-wrapper'>
        <FlipClockCountdown
          to={targetDate} // Target date for the countdown
          className="flip-clock" // Add your custom class if needed
          labels={['DIAS', 'HORAS', 'MINUTOS', 'SEGUNDOS']} // Display labels for the countdown
          duration={0.5} // Animation duration for flipping effect
        />
        </div>

      <div className="content-box">
        {showSentence && (
          <>
          {/* Conditionally render the image div only if the sentence has an image */}
          {currentSentence.image && (
              <div className="image-container">
                <img src={currentSentence.image} alt="Canela" />
              </div>
            )}
            <p className="sentence">
              <TypeAnimation
                key={sentenceIndex} // Use sentenceIndex as the key to force re-render
                sequence={[currentSentence.text]} // Display the current sentence's text
                wrapper="span"
                cursor={true} // Show typing cursor
                speed={5} // Speed of typing
                repeat={0} // Do not repeat the typing animation
                style={{ display: 'inline-block' }}
              />
            </p>

          </>
        )}
        {clicked && timeRemaining > 0 && (
          <p className="timer">
            Próxima em: {formatTime(timeRemaining)}
          </p>
        )}
        {showButton && (
          <button className="start-button" onClick={handleClick}>
            {clicked ? 'Próxima' : 'Começar!'}
          </button>
        )}
      </div>
    </div>
  );
};

export default SentenceToggle;
